'use strict';

var helpers = require('./helpers.core');

/**
 * @namespace Chart.helpers.canvas
 */
var exports = module.exports = {
	/**
	 * Clears the entire canvas associated to the given `chart`.
	 * @param {Chart} chart - The chart for which to clear the canvas.
	 */
	clear: function(chart) {
		chart.ctx.clearRect(0, 0, chart.width, chart.height);
	},

	/**
	 * Creates a "path" for a rectangle with rounded corners at position (x, y) with a
	 * given size (width, height) and the same `radius` for all corners.
	 * @param {CanvasRenderingContext2D} ctx - The canvas 2D Context.
	 * @param {Number} x - The x axis of the coordinate for the rectangle starting point.
	 * @param {Number} y - The y axis of the coordinate for the rectangle starting point.
	 * @param {Number} width - The rectangle's width.
	 * @param {Number} height - The rectangle's height.
	 * @param {Number} radius - The rounded amount (in pixels) for the four corners.
	 * @todo handle `radius` as top-left, top-right, bottom-right, bottom-left array/object?
	 */
	roundedRect: function(ctx, x, y, width, height, radius) {
		if (radius) {
			// NOTE(SB) `epsilon` helps to prevent minor artifacts appearing
			// on Chrome when `r` is exactly half the height or the width.
			var epsilon = 0.0000001;
			var r = Math.min(radius, (height / 2) - epsilon, (width / 2) - epsilon);

			ctx.moveTo(x + r, y);
			ctx.lineTo(x + width - r, y);
			ctx.arcTo(x + width, y, x + width, y + r, r);
			ctx.lineTo(x + width, y + height - r);
			ctx.arcTo(x + width, y + height, x + width - r, y + height, r);
			ctx.lineTo(x + r, y + height);
			ctx.arcTo(x, y + height, x, y + height - r, r);
			ctx.lineTo(x, y + r);
			ctx.arcTo(x, y, x + r, y, r);
			ctx.closePath();
			ctx.moveTo(x, y);
		} else {
			ctx.rect(x, y, width, height);
		}
	},

	drawPoint: function(ctx, style, radius, x, y, rotation) {
		var type, edgeLength, xOffset, yOffset, height, size;
		rotation = rotation || 0;

		if (style && typeof style === 'object') {
			type = style.toString();
			if (type === '[object HTMLImageElement]' || type === '[object HTMLCanvasElement]') {
				ctx.drawImage(style, x - style.width / 2, y - style.height / 2, style.width, style.height);
				return;
			}
		}

		if (isNaN(radius) || radius <= 0) {
			return;
		}

		ctx.save();
		ctx.translate(x, y);
		ctx.rotate(rotation * Math.PI / 180);
		ctx.beginPath();

		switch (style) {
		// Default includes circle
		default:
			ctx.arc(0, 0, radius, 0, Math.PI * 2);
			ctx.closePath();
			break;
		case 'triangle':
			edgeLength = 3 * radius / Math.sqrt(3);
			height = edgeLength * Math.sqrt(3) / 2;
			ctx.moveTo(-edgeLength / 2, height / 3);
			ctx.lineTo(edgeLength / 2, height / 3);
			ctx.lineTo(0, -2 * height / 3);
			ctx.closePath();
			break;
		case 'rect':
			size = 1 / Math.SQRT2 * radius;
			ctx.rect(-size, -size, 2 * size, 2 * size);
			break;
		case 'rectRounded':
			var offset = radius / Math.SQRT2;
			var leftX = -offset;
			var topY = -offset;
			var sideSize = Math.SQRT2 * radius;

			// NOTE(SB) the rounded rect implementation changed to use `arcTo`
			// instead of `quadraticCurveTo` since it generates better results
			// when rect is almost a circle. 0.425 (instead of 0.5) produces
			// results visually closer to the previous impl.
			this.roundedRect(ctx, leftX, topY, sideSize, sideSize, radius * 0.425);
			break;
		case 'rectRot':
			size = 1 / Math.SQRT2 * radius;
			ctx.moveTo(-size, 0);
			ctx.lineTo(0, size);
			ctx.lineTo(size, 0);
			ctx.lineTo(0, -size);
			ctx.closePath();
			break;
		case 'cross':
			ctx.moveTo(0, radius);
			ctx.lineTo(0, -radius);
			ctx.moveTo(-radius, 0);
			ctx.lineTo(radius, 0);
			break;
		case 'crossRot':
			xOffset = Math.cos(Math.PI / 4) * radius;
			yOffset = Math.sin(Math.PI / 4) * radius;
			ctx.moveTo(-xOffset, -yOffset);
			ctx.lineTo(xOffset, yOffset);
			ctx.moveTo(-xOffset, yOffset);
			ctx.lineTo(xOffset, -yOffset);
			break;
		case 'star':
			ctx.moveTo(0, radius);
			ctx.lineTo(0, -radius);
			ctx.moveTo(-radius, 0);
			ctx.lineTo(radius, 0);
			xOffset = Math.cos(Math.PI / 4) * radius;
			yOffset = Math.sin(Math.PI / 4) * radius;
			ctx.moveTo(-xOffset, -yOffset);
			ctx.lineTo(xOffset, yOffset);
			ctx.moveTo(-xOffset, yOffset);
			ctx.lineTo(xOffset, -yOffset);
			break;
		case 'line':
			ctx.moveTo(-radius, 0);
			ctx.lineTo(radius, 0);
			break;
		case 'dash':
			ctx.moveTo(0, 0);
			ctx.lineTo(radius, 0);
			break;
		}

		ctx.fill();
		ctx.stroke();
		ctx.restore();
	},

	clipArea: function(ctx, area) {
		ctx.save();
		ctx.beginPath();
		ctx.rect(area.left, area.top, area.right - area.left, area.bottom - area.top);
		ctx.clip();
	},

	unclipArea: function(ctx) {
		ctx.restore();
	},

	lineTo: function(ctx, previous, target, flip) {
		if (target.steppedLine) {
			if ((target.steppedLine === 'after' && !flip) || (target.steppedLine !== 'after' && flip)) {
				ctx.lineTo(previous.x, target.y);
			} else {
				ctx.lineTo(target.x, previous.y);
			}
			ctx.lineTo(target.x, target.y);
			return;
		}

		if (!target.tension) {
			ctx.lineTo(target.x, target.y);
			return;
		}

		ctx.bezierCurveTo(
			flip ? previous.controlPointPreviousX : previous.controlPointNextX,
			flip ? previous.controlPointPreviousY : previous.controlPointNextY,
			flip ? target.controlPointNextX : target.controlPointPreviousX,
			flip ? target.controlPointNextY : target.controlPointPreviousY,
			target.x,
			target.y);
	}
};

// DEPRECATIONS

/**
 * Provided for backward compatibility, use Chart.helpers.canvas.clear instead.
 * @namespace Chart.helpers.clear
 * @deprecated since version 2.7.0
 * @todo remove at version 3
 * @private
 */
helpers.clear = exports.clear;

/**
 * Provided for backward compatibility, use Chart.helpers.canvas.roundedRect instead.
 * @namespace Chart.helpers.drawRoundedRectangle
 * @deprecated since version 2.7.0
 * @todo remove at version 3
 * @private
 */
helpers.drawRoundedRectangle = function(ctx) {
	ctx.beginPath();
	exports.roundedRect.apply(exports, arguments);
};
