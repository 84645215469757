/* global window: false */
'use strict';

var defaults = require('./core.defaults');
var helpers = require('../helpers/index');

defaults._set('global', {
	animation: {
		duration: 1000,
		easing: 'easeOutQuart',
		onProgress: helpers.noop,
		onComplete: helpers.noop
	}
});

module.exports = {
	frameDuration: 17,
	animations: [],
	dropFrames: 0,
	request: null,

	/**
	 * @param {Chart} chart - The chart to animate.
	 * @param {Chart.Animation} animation - The animation that we will animate.
	 * @param {Number} duration - The animation duration in ms.
	 * @param {Boolean} lazy - if true, the chart is not marked as animating to enable more responsive interactions
	 */
	addAnimation: function(chart, animation, duration, lazy) {
		var animations = this.animations;
		var i, ilen;

		animation.chart = chart;

		if (!lazy) {
			chart.animating = true;
		}

		for (i = 0, ilen = animations.length; i < ilen; ++i) {
			if (animations[i].chart === chart) {
				animations[i] = animation;
				return;
			}
		}

		animations.push(animation);

		// If there are no animations queued, manually kickstart a digest, for lack of a better word
		if (animations.length === 1) {
			this.requestAnimationFrame();
		}
	},

	cancelAnimation: function(chart) {
		var index = helpers.findIndex(this.animations, function(animation) {
			return animation.chart === chart;
		});

		if (index !== -1) {
			this.animations.splice(index, 1);
			chart.animating = false;
		}
	},

	requestAnimationFrame: function() {
		var me = this;
		if (me.request === null) {
			// Skip animation frame requests until the active one is executed.
			// This can happen when processing mouse events, e.g. 'mousemove'
			// and 'mouseout' events will trigger multiple renders.
			me.request = helpers.requestAnimFrame.call(window, function() {
				me.request = null;
				me.startDigest();
			});
		}
	},

	/**
	 * @private
	 */
	startDigest: function() {
		var me = this;
		var startTime = Date.now();
		var framesToDrop = 0;

		if (me.dropFrames > 1) {
			framesToDrop = Math.floor(me.dropFrames);
			me.dropFrames = me.dropFrames % 1;
		}

		me.advance(1 + framesToDrop);

		var endTime = Date.now();

		me.dropFrames += (endTime - startTime) / me.frameDuration;

		// Do we have more stuff to animate?
		if (me.animations.length > 0) {
			me.requestAnimationFrame();
		}
	},

	/**
	 * @private
	 */
	advance: function(count) {
		var animations = this.animations;
		var animation, chart;
		var i = 0;

		while (i < animations.length) {
			animation = animations[i];
			chart = animation.chart;

			animation.currentStep = (animation.currentStep || 0) + count;
			animation.currentStep = Math.min(animation.currentStep, animation.numSteps);

			helpers.callback(animation.render, [chart, animation], chart);
			helpers.callback(animation.onAnimationProgress, [animation], chart);

			if (animation.currentStep >= animation.numSteps) {
				helpers.callback(animation.onAnimationComplete, [animation], chart);
				chart.animating = false;
				animations.splice(i, 1);
			} else {
				++i;
			}
		}
	}
};
