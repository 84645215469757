"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ariaHidden = ariaHidden;
exports.ariaHiddenSiblings = ariaHiddenSiblings;
var BLACKLIST = ['template', 'script', 'style'];

function isHidable(node) {
  return node.nodeType === 1 && BLACKLIST.indexOf(node.tagName.toLowerCase()) === -1;
}

function siblings(container, mount, currentNode, callback) {
  var blacklist = [mount, currentNode];
  [].forEach.call(container.children, function (node) {
    if (blacklist.indexOf(node) === -1 && isHidable(node)) {
      callback(node);
    }
  });
}

function ariaHidden(node, show) {
  if (show) {
    node.setAttribute('aria-hidden', 'true');
  } else {
    node.removeAttribute('aria-hidden');
  }
}

function ariaHiddenSiblings(container, mountNode, currentNode, show) {
  siblings(container, mountNode, currentNode, function (node) {
    return ariaHidden(node, show);
  });
}